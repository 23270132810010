export const statisticsRes = [
  'servers',
  'images',
  'guestimages',
  'disks',
  'snapshots',
  'instance_snapshots',
  'diskbackups',
  'instancebackups',
  'secgroups',
  'networks',
  'eips',
  'hosts',
  'vpcs',
  'wires',
  'dbinstances',
  'elasticcaches',
  'mongodbs',
  'cdn_domains',
  'loadbalancers',
  'buckets',
]
