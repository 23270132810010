var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.inDialog)?_c('a-form-item',{attrs:{"label":_vm.$t('compute.bastionHost.bastion_host')}},[_c('a-tooltip',{attrs:{"title":_vm.switchDisabled ? _vm.$t('compute.bastionHost.bastion_host.switch_disabled') : null}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorator.bastion_host_enable),expression:"decorator.bastion_host_enable"}],attrs:{"checkedChildren":_vm.$t('compute.text_115'),"unCheckedChildren":_vm.$t('compute.text_116'),"disabled":_vm.switchDisabled},on:{"change":_vm.changeHandle}})],1)],1):_vm._e(),(_vm.inDialog || _vm.bastionHostEnable)?[_c('a-form-item',{staticClass:"mt-2",attrs:{"label":_vm.inDialog ? _vm.$t('compute.bastionHost.bastion_host') : _vm.$t('compute.bastionHost.add_bastion_host')}},[_c('base-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorator.bastion_host_id),expression:"decorator.bastion_host_id"}],attrs:{"options":_vm.bastionHosts,"filterable":true,"select-props":{
          placeholder: _vm.$t('compute.bastionHost.bastion_host.placeholder'),
          loading: _vm.bastionHostLoading
        }},on:{"change":_vm.bastionHostChangeHandle}})],1),_c('a-form-item',{staticClass:"mt-2",attrs:{"label":_vm.$t('compute.bastionHost.node')}},[_c('base-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorator.nodes),expression:"decorator.nodes"}],attrs:{"options":_vm.nodes,"filterable":true,"select-props":{
          placeholder: _vm.$t('compute.bastionHost.node.placeholder'),
          allowClear: true,
          mode: 'multiple',
          loading: _vm.nodeLoading
        }}})],1),_c('a-form-item',{staticClass:"mt-2",attrs:{"label":_vm.$t('compute.bastionHost.port')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorator.port),expression:"decorator.port"}],attrs:{"placeholder":_vm.$t('compute.bastionHost.port.placeholder')}})],1),_c('a-form-item',{staticClass:"mt-2",attrs:{"label":_vm.$t('compute.bastionHost.privileged_account')}},[_c('base-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorator.privileged_accounts),expression:"decorator.privileged_accounts"}],attrs:{"options":_vm.privilegedAccounts,"filterable":true,"select-props":{
          placeholder: _vm.$t('compute.bastionHost.privileged_account.placeholder'),
          allowClear: true,
          loading: _vm.accountLoading
        }}})],1),_c('a-form-item',{staticClass:"mt-2",attrs:{"label":_vm.$t('compute.bastionHost.account')}},[_c('base-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorator.accounts),expression:"decorator.accounts"}],attrs:{"options":_vm.accounts,"filterable":true,"select-props":{
          placeholder: _vm.$t('compute.bastionHost.account.placeholder'),
          allowClear: true,
          mode: 'multiple',
          loading: _vm.accountLoading
          }}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }