export default {
  help: {
    cloudregionZone: `
      <h4>地域</h4>
      <p>地域とは、ECSインスタンスが存在する物理的な場所を指します。</p>
      <h4>使用可能な領域</h4>
      <p>利用可能領域とは、同じ地域内で電力とネットワークが互いに独立している物理領域を指す。同じ地域内の空き領域と空き領域の間でイントラネットが相互接続され、空き領域間で障害の分離が可能です。
      アプリケーションにより高いディザスタリカバリ能力が必要な場合は、VM ECSインスタンスを同じ地域の異なる空き領域に配置することをお勧めします。
      アプリケーションがインスタンス間で低いネットワーク遅延を必要とする場合は、ECSインスタンスを同じ空き領域に作成することをお勧めします。</p>
    `,
    serverPassword: `
      <h4>相関キー</h4>
      <p>SSHキーを設定してサーバにログインする</p>
      <h4>ミラー設定の保持</h4>
      <p>既存のパスワードをミラーリングするには、ミラーリングを選択した既存のパスワードを知っていることを確認してください。</p>
    `,
    keypairPassword: `
      <h4>相関キー</h4>
      <p>SSHキーを設定してサーバにログインする</p>
    `,
    imagePassword: `
      <h4>ミラー設定の保持</h4>
      <p>既存のパスワードをミラーリングするには、ミラーリングを選択した既存のパスワードを知っていることを確認してください。</p>
    `,
    networkIpSubnets: `
      <p>1.VLAN IDはネットワーク物理的隔離のために使用され、デフォルト1、同じVLAN IDのIPサブネットは相互に接続され、異なるVLAN IDのIPサブネットは不通である。</p>
      <p>2.複数のIPサブネットを作成する場合、システムは名前を自動的に割り当てる</p>
      <p>例：名前がnetworkで、3つのIPサブネットを追加</p>
      <p>名前は、network 0、network 1、network 2の順です。</p>
    `,
    networkPolicy: `
      <p>デフォルトのポリシーは、物理マシンは高アドレスから割り当てられ、仮想マシンは低アドレスから割り当てられる</p>
    `,
    networkDomain: `
      <p>システムがホストにIPを割り当てると、そのIPを指すドメイン名レコードが同時に作成され、ドメイン名はホスト名+ホストドメイン名接尾辞で構成されます。例：</p>
      <p>ホスト名はvm 01、ホストipは192.168.1.1</p>
      <p>ホストドメイン名の接尾辞はgh.baidu.com</p>
      <p>ホストの作成に成功すると、ping vm 01.gh.baidu.comはvm 01のipを取得します</p>
    `,
    cloudaccountSetAutoSyncTime: `
      <div>最小時間間隔は30分</div>
    `,
    cloudaccountAutoCreateProject: `
      <div>アカウントにプロジェクト情報が設定されている場合は、クラウド上のプロジェクト（またはクラウドサブスクリプション）と同じ名前のローカルプロジェクトを自動的に作成します（ローカルプロジェクトと名前を変更すると、システムはデフォルトで「-1」を追加します）。</div>
    `,
    useCasProject: `
      <div>CAS内の同じ名前のプロジェクトにユーザーを優先的に同期させ（存在しない場合は自動的に作成されます）、CAS内の一部のユーザーがプロジェクトを持っていない場合はデフォルトのプロジェクトを使用します。</div>
    `,
    useCasRole: `
      <div>ユーザーをそのシステム内のアイテムに同期させる場合は、CAS内の同名のロールを優先し、システム内に対応するロールがない場合はデフォルトのロールを使用します。</div>
    `,
    ipSupplement: '今回のコミットIPが実際のIPと一致していることを確認してください。コミットが成功すると修正できません',
    monitorDashboardOverviewTips: 'リソース概要統計にはサイクルがあり、統計結果には遅延があります',
    disable_user_on_import: '有効：ユーザーのステータスが有効であることを示す場合、今回のインバウンドユーザーは本システムを使用でき、無効にすると、インポートに成功した後にユーザーリストで再設定することができます',
    defaultServerNetwork: '自動スケジュール可能なIPサブネットから仮想マシンにIPアドレスを割り当てる',
    deleteDiskAtTheSameTime: 'チェックされていない場合はシステムディスクのみを削除し、チェックされてマウントされたデータディスクはシステムディスクとともに削除されます',
    shutdownStopCharging: `電源を切ったら、次の情報に注意してください。<br />
                          * 電源をオフにすると、CPU、メモリが解放され、CPU、メモリの課金が停止されます。関連するハードディスク、ミラー、パブリックネットワーク帯域幅（課金が必要な場合）は依然として課金されます<br />
                          * 電源を切ると、インスタンスのCPU、メモリは保持されなくなるので、インスタンスを再起動する際に失敗する可能性があります。この時点で、再起動を試みたり、別の時間に再起動したりすることができます。<br />
                          * 電源をオフにすると、通常のパブリックネットワークIPが解放されます。電源をオフにする前に、弾力性のあるパブリックネットワークIPに変換することをお勧めします<br />
                          * シャットダウン後、フレックスパブリックネットワークIPは保留され、解放されず、シャットダウン中も価格を徴収しません<br />
                          * シャットダウン中のインスタンスの多い操作（構成の調整、システムの再ロードなど）はサポートできない場合があります。操作が必要な場合は、電源を入れてから実行してください<br />
                          * シャットダウンをサポートしていない一部のインスタンスでは、シャットダウン後も通常の価格が継続されます`,
    billNegativeNumberReson: '請求書にマイナスが出たのは、バウチャーマシンが早期に返金をリリースしたり、クーポンを使用した後に返金したり、アカウントが享受した他の特典の返金など、返金が発生したためです。',
    billCurrentYearFee: '本年の消費者は、現在選択されている期日の当年費用として表示されます',
    restartNetworkToEffectIp: 'IPを交換するには、同時にNICを再起動してから有効になります',
    billResourceSharedTip: '有効化されたら、年次貸切月の資源費請求書を具体的な使用日に割り当て、割り当て後総費用=量別支払費用+年次貸切月割り当て後費用',
    secgroupAdminGuestCnt: '管理セキュリティグループとしてバインドされたVMの数',
    secgroupSystemGuestCnt: 'セキュリティグループがシステムリソースとしてバインドしているVMの数',
    v2vTransferType: '移行が成功すると、同じ構成のKVM VMが自動的に1台新規に作成され、IPが新しいインスタンスに移行され、元のインスタンスが保持されます',
    billCostConversionRateTip: '資源価格係数は当該製品に対する公式サイト価格である',
  },
  notify: {
    TooManyRequests: '認証コードを送信する頻度が高すぎるので、後で再試行してください',
    NotFoundError: 'リソースが存在しません',
    FindUserByMobile: 'この携帯番号は登録されていません',
    IncorrectUsernameOrPassword: 'ユーザー名またはパスワードが正しくありません',
    InvalidCredentialError: '無効な資格認定情報',
    TooManyFailedAttempts: '試行失敗が多すぎる',
    InputParameterError: 'パラメータエラー',
    WrongCaptchaLength: '検証コード長が正しくありません',
    UserLocked: 'ユーザーのロック',
    InvalidIdpStatus: '無効な認証ソースステータス',
    InvalidIdpStatusDetail: 'ユーザーホーム認証ソースが無効になっているか、またはステータスが無効であるかを確認してください',
  },
}
